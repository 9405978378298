import styled from "@emotion/styled";

const PageWrapper = styled.div`
  /* background-color: ${(props) => props.theme.bgPrimary}; */
  /* min-height: 100vh; */
`;
const GifImage = styled.video`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "50px 0 0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const GifImage2 = styled.image`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "50px 0 0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "0")};
  left: ${(props) => (props.l ? props.l : "0")};
  transform: ${(props) => (props.tran ? props.tran : "translate(50%,50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "300px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
         max-width: ${(props) => (props.mw899 )};
         padding: ${(props) => (props.m ? props.m : "0 20px 0 0")};

  }
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  }
`;
export { PageWrapper, GifImage, AbsoluiteImage,GifImage2 };
