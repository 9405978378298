import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "ABOUT",
        link: "#about",
        },
        {
        title: "TOKENOMICS",
        link: "#tokenomics",
        },
        {
        title: "HOW TO BUY",
        link: "#how to buy",
        },
        {
        title: "Dexscreener",
        link: "https://dexscreener.com/solana/cdfnnofq27q9upkumdgfdyjrtkypvzi1aeps744w9lcd",
        target:"blank"
        },
        {
        title: "Dextools",
        link: "https://www.dextools.io/app/en/solana/pair-explorer/FvDW9uCAaX4hqHDTTDcnqgCyHXaPVfCMUmwhytFWpump?t=1730908337114",
        target:"blank"
        },
        {
        title: "TELEGRAM",
        link: "https://t.me/lordtrumpSOL",
        target:"blank"
        },
        {
        title: "TWITTER",
        link: "https://x.com/lordtrumpSOL",
        target:"blank"
        },
    ];
  return (
    <Container>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </Container>
  )
}

export default LockerMenu