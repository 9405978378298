import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import {
  GifImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: center;
  padding: 100px 0 0;
`;
const ContinerWraper = styled(Container)`
  background: ${(props) => props.theme.bgPrimary};
  height: auto;
  width: 100%;
  padding: 0 !important;
`;

const AboutSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="about">
      <Container>
        <GridWraper container>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <GifImage mw="250px" mw599="180px" src="./Images/Crypto bros.png" />
          </Grid>
          <Grid item xs={12}>
            <SectionsHeading ta="center">About MAGA</SectionsHeading>
          </Grid>
          <Grid item xs={10} md={8}>
            <Paragraph>
            Welcome to $MAGA, the token that’s flipping off the establishment while shooting for the moon. We’re not here for your “safe investments” or polished crypto babble. $MAGA is pure, unfiltered 
chaos on the blockchain.

<Typography sx={{paddingTop:'20px', fontFamily:'PoppinsR',fontSize:'18px'}}>
Buy it. HODL it. Annoy everyone who isn’t with us. We buy up tokens like no tomorrow, driving up scarcity, and throw cash into our “Freedom Fund” to keep the hype pumping.
</Typography>

            </Paragraph>
          </Grid>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default AboutSection;
