import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Heading = styled(Typography)`
    padding: ${props=> props.p ? props.p : "5px 0px"};
    margin: ${props=> props.m ? props.m : "0"};
    text-align: ${props=> props.ta ? props.ta : "center"};
    font-size: ${props=> props.fs ? props.fs : "18px"};
    font-family: ${props=> props.fm ? props.fm : "PoppinsR"};
    @media(max-width:599px){
      padding: ${props=> props.p599 };
      font-size: ${props=> props.fs599};
  }
`

const Paragraph = (props) => {
    const {children} = props
  
  return (
    <Heading color="#fff" {...props}>
        {children}
    </Heading>
  )
}

export default Paragraph